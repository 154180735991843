.roompage-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Poppins', 'sans-serif';

    h2{
        font-size: 1.2rem;
        width: 100%;
        padding: 1rem;
        span{
            background: linear-gradient(140deg, #00c29a, #6cc487, #abcd77, #e6cc6d);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
    .lk-room-container{
        width: 80%;
    }
}

@media screen and (min-width: 800px){
    .roompage-container{
        h2{
            font-size: 1.5rem;
        }
        .lk-room-container{
            width: 90%;
        }
    }
}