*{
  margin: 0;padding: 0;box-sizing: border-box;
  font-style: "Poppins", 'sans-serif';
  background: #0f0f0f;
  color: #fff;
}

.App{
  min-height: 100vh;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}