.landingpage-container {
  min-height: 100vh;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Poppins", 'sans-serif';

  .landingpage-content{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h2{
        width: 80%;
        font-size: 3rem;
        text-align: center;
        font-weight: 700;
        margin: 0.3rem 0;
    }
    p{
        width: 50%;
        color: #ccc;
        font-size: 0.9rem;
        text-align: center;
    }
  }
  .landingpage-form-container{
    padding: 2rem;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 3%;
    color: #ccc;
    .landingpage-form-holder{
      width: 50%;
      padding: 1rem;
    }
    .landingpage-options{
      width: 50%;
      display: none;
      img{
        width: 70%;
        max-height: 60%;
      }
    }
  }
  .landingpage-toggle-auth{
    p{
      cursor: pointer;
      color: #ccc;
      opacity: 0.7;
      font-size: 0.8rem;
    }
  }
}


@media screen and (max-width: 800px){
  .landingpage-container{
    .landingpage-content{
      h2{
        font-size: 2rem;
      }
      p{
        width: 80%;
        font-size: 0.8rem;
      }
    }
    .landingpage-form-container{
      width: 90%;
      flex-direction: column;
      gap: 0;
      .landingpage-form-holder{
        width: 100%;
        padding: 0;
      }
      .landingpage-options{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
        img{
          width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}