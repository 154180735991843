.navbar-container {
  width: 100%;
  height: 15vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0 0.5rem;

  .navbar-logo {
    padding: 0.5rem;
    h3 {
      font-size: 1.2rem;
      font-family: "Poppins", "sans-serif";
      background: linear-gradient(140deg, #00c29a, #6cc487, #abcd77, #e6cc6d);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .navbar-navlinks {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    .navbar-navlinks-list {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: row;
      list-style-type: none;

      li {
        font-family: "Poppins", "sans-serif";
        a {
          text-decoration: none;

          &:hover {
            color: #6cc487;
          }
        }
      }
    }
  }

  .checkbtn {
    font-size: 30px;
    color: #92e3a9;
    cursor: pointer;
    display: none;
  }

  #check {
    display: none;
  }

  .fa-bars::before {
    color: #92e3a9;
    content: "\f0c9";
  }
}

@media all and (max-width: 880px) {
  nav {
    .navbar-navlinks {
        justify-content: right;
      .checkbtn {
        display: block;
      }

      .navbar-navlinks-list {
        margin-top: 20px;
        position: absolute;
        z-index: 120;
        background-color: #1b1b1b;
        top: 10%;
        left: -100%;
        transition: all 0.5s;
        border: 3px solid #6cc487;

        li{
            background: #1b1b1b;
            font-size: 0.9rem;
            a{
                background-color: #1b1b1b;
                text-decoration: none;
                border: none;
            }
        }
      }
    }
  }
  
  #check:checked ~ {
    ul {
      left: -0%;
      transition: all 0.5s ease-in;
    }

    .checkbtn i::before {
      content: "\f00d";
    }
  }
}
